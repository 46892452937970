<template>
  <svg width="100" height="20" viewBox="0 0 100 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.091 0L5.87012 9.33213H16.3074L11.091 0Z" fill="#31B886"/>
    <path d="M4.56714 12.6689L0 19.9997H9.13428V12.6689H4.56714Z" fill="#31B886"/>
    <path d="M13.0479 12.6689H17.615L22.1777 19.9997H13.0479V12.6689Z" fill="#31B886"/>
    <path d="M29.3112 7.26297V17.0403H26.6655V7.26297H23.3086V4.92432H32.6681V7.26297H29.3112Z" fill="#A0A0A0"/>
    <path d="M45.2695 4.92432V7.33043H39.3684V9.8265H44.6776V12.1337H39.3684V14.6477H45.4594V17.0403H36.7227V4.92432H45.2695Z" fill="#A0A0A0"/>
    <path d="M49.5098 17.0403V4.92432H52.1555V14.6297H57.2085V17.0403H49.5098Z" fill="#A0A0A0"/>
    <path d="M69.5587 4.92432H72.2045V17.0403H69.5587L63.9094 9.44871V17.0403H61.2637V4.92432H63.7416L69.5587 12.7228V4.92432Z" fill="#A0A0A0"/>
    <path d="M82.3458 17.0403H79.7V12.2551L75.5967 4.92432H78.4633L81.0251 9.43072L83.5825 4.92432H86.4491L82.3458 12.2551V17.0403Z" fill="#A0A0A0"/>
    <path d="M96.5067 4.92432H99.6958L95.9987 10.7665L100.001 17.0403H96.7806L94.254 13.0511L91.7408 17.0403H88.5518L92.5403 10.8339L88.8256 4.92432H91.997L94.254 8.50875L96.5067 4.92432Z" fill="#A0A0A0"/>
  </svg>
</template>

<script>
export default {
  name: 'Telnyx-logo-icon',
  inheritAttrs: false,
}
</script>
