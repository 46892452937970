<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    class="outlined-button"
    :height="height"
    :min-width="minWidth"
    outlined
    :ripple="false"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: 'OutlinedButton',
  inheritAttrs: false,
  props: {
    height: {
      type: String | Number,
      default: 'auto'
    },
    minWidth: {
      type: String | Number,
      default: 'auto'
    }
  },
}
</script>

<style lang="scss">
.v-application {
  & > .v-application--wrap {
    .v-btn {
      &.outlined-button {
        border-color: var(--v-inputBorder-base);
  
        &:hover {
          border-color: var(--v-inputBorderHover-base)!important;
        }
      }
    }
  }
}
</style>
