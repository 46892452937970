<template>
  <v-container class="pa-0 pbxLayoutBg" fluid fill-height>
    <div class="pbx-bg"></div>
    <v-row no-gutters align="center" justify="center">
      <v-card
        class="pbxBg pbxConnect"
        :class="isXs ? 'pa-15 pa-sm-20' : 'pa-7'"
        :max-width="isMobile ? '598' : '90%'"
        outlined
        flat
      >
        <v-row class="container-pbx-info pb-10 pb-sm-17" no-gutters>
          <v-col cols="12">
            <div
              class="d-flex rounded connect-pbx-info"
              :class="isXs ? 'px-10 py-8 pa-sm-15' : 'px-7 py-5'"
            >
              <v-row align="center" no-gutters>
                <v-col  cols="2" class="d-none d-sm-block">
                  <v-icon color="pbxAlarmIcon" size="70">$alarmIcon</v-icon>
                </v-col>
                
                <v-col class="pl-sm-16" cols="12" sm="10">
                  <div
                    class="pbxTextInfo--text"
                    :class="isMobile ? 'font-xs' : 'font-xs-mobile'"
                  >
                    You can take advantage of the Dialics call tracking feature by connecting your Plivo or Telnyx account. If you have existing phone numbers, they'll be automatically pulled into your dashboard. You can also purchase new phone numbers directly from the Dialics dashboard above.
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        
        <v-row v-if="loadingPlatforms" justify="center" align="center">
          <div class="py-5 pbxTextTitle--text">Loading...</div>
        </v-row>
        
        <v-row v-else-if="!currentPlatform">
          <v-col class="pr-sm-11 pb-6 pb-sm-4 " cols="12">
            <span class="font-row-title pbxTextTitle--text">Choose call-tracking platform</span>
          </v-col>
          
          <v-col class="d-flex align-center flex-wrap" cols="12 pt-8">
            <div
              v-for="(platform, index) in platforms"
              :key="index"
            >
              <OutlinedButton class="appendArrowIcon pa-8 mr-12" @click="selectPlatform(platform)">
                <component :is="platform.name + 'Logo'"/>
              </OutlinedButton>
            </div>
  
            <v-spacer />
  
            <div class="font-xs textLink--text pr-10 py-6">
              <TextLink @click="logout">Logout</TextLink>
            </div>
          </v-col>
        
        </v-row>
        
        <v-form v-if="currentPlatform" ref="form" v-model="validateForm">
          <v-row class="form-pbx pb-sm-15 pr-sm-15" no-gutters>
            <template v-if="currentPlatform.name === 'plivo'">
              <v-col class="pr-sm-11 pb-4 pb-sm-0 text-sm-end" cols="12" sm="5">
                <span class="font-row-title pbxTextTitle--text">Connect Plivo</span>
              </v-col>
              
              <v-col class="pb-9 pb-sm-14" cols="12" sm="7">
                <div class="font-normal pbxTextSubTitle--text pb-4">
                  Please authorize your Plivo account to begin using Dialics's call tracking features
                </div>
                
                <div class="font-normal pbxTextSubTitle--text">To get API key please navigate to your <br/>
                  <a
                    href="https://console.plivo.com/dashboard/"
                    class="setting-link pbxTextLink--text"
                  >Plivo Settings page</a>
                </div>
              </v-col>
              
              <v-col
                class="d-flex flex-column text-sm-end justify-center pb-4 pb-sm-10 pr-sm-11"
                cols="12" sm="5"
              >
                <span class="font-normal pbxFieldName--text">Plivo Account Name</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="namePlivo"
                ></portal-target>
              </v-col>
              <v-col class="pb-10 pbx-field" cols="12" sm="7">
                <FormField
                  v-model="formData.name"
                  :rules="rules"
                >
                  <template #message="{ key, message }">
                    <portal to="namePlivo" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </FormField>
              </v-col>
              
              <v-col
                class="d-flex flex-column text-sm-end justify-center pb-4 pb-sm-10 pr-sm-11"
                cols="12" sm="5"
              >
                <span class="font-normal pbxFieldName--text">Plivo Account SID</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="accountSid"
                ></portal-target>
              </v-col>
              <v-col class="pb-10 pbx-field"  cols="12" sm="7">
                <FormField
                  v-model="formData.id"
                  :rules="rules"
                >
                  <template #message="{ key, message }">
                    <portal to="accountSid" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </FormField>
              </v-col>
              
              <v-col
                class="d-flex flex-column text-sm-end justify-center pb-4 pb-sm-10 pr-sm-11"
                cols="12" sm="5"
              >
                <span class="font-normal pbxFieldName--text">Plivo Auth Token</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="tokenPlivo"
                ></portal-target>
              </v-col>
              <v-col class="pb-10 pbx-field"  cols="12" sm="7">
                <FormField
                  v-model="formData.token"
                  :rules="rules"
                >
                  <template #message="{ key, message }">
                    <portal to="tokenPlivo" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </FormField>
              </v-col>
            </template>
            
            <template v-if="currentPlatform.name === 'telnyx'">
              <v-col class="pr-sm-11 pb-4 pb-sm-0 text-sm-end" cols="12" sm="5">
                <span class="font-row-title pbxTextTitle--text">Connect Telnyx</span>
              </v-col>
              
              <v-col class="pb-9 pb-sm-14" cols="12" sm="7">
                <div class="font-normal pbxTextSubTitle--text pb-4">
                  Please authorize your Telnyx account to begin using Dialics's call tracking features
                </div>
                
                <div class="font-normal pbxTextSubTitle--text">To get API key please navigate to your <br/>
                  <a
                    href="https://portal.telnyx.com/#/login/sign-in"
                    class="setting-link pbxTextLink--text"
                  >Telnyx Settings page</a>
                </div>
              </v-col>
              
              <v-col
                class="d-flex flex-column text-sm-end justify-center pb-4 pb-sm-10 pr-sm-11"
                cols="12" sm="5"
              >
                <span class="font-normal pbxFieldName--text">Telnyx Auth Token</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="tokenTelnyx"
                ></portal-target>
              </v-col>
              <v-col class="pb-10 pbx-field"  cols="12" sm="7">
                <FormField
                  v-model="formData.token"
                  :rules="rules"
                >
                  <template #message="{ key, message }">
                    <portal to="tokenTelnyx" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </FormField>
              </v-col>
            </template>
            
            <v-col cols="12" class="d-flex align-center pt-6 pt-sm-10 pbx-field">
              <OutlinedButton height="48" class="px-11 pbxFieldName--text" @click="currentPlatform = null">Back</OutlinedButton>
              
              <v-spacer />
              
              <div class="font-xs textLink--text pr-10">
                <TextLink @click="logout">Logout</TextLink>
              </div>
              
              <ActionButton :loading="loading" @click="connect">Connect</ActionButton>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import FormField from '@/components/inputs/FormField'
import OutlinedButton from '@/components/buttons/OutlinedButton'
import ActionButton from '@/components/buttons/ActionButton'
import TextLink from '@/components/links/TextLink'
import TelnyxLogo  from '@/components/icons/logo/Telnyx-logo-icon'
import PlivoLogo  from '@/components/icons/logo/Plivo-logo-icon'

import { mapState, mapActions, mapMutations } from 'vuex'
import { Types as authTypes } from '@/store/modules/auth'
import { attachPbx, getPlatforms } from '@/api/auth-routes'

export default {
  name: 'ConnectPbx',
  components: { FormField, OutlinedButton, ActionButton, TextLink, TelnyxLogo, PlivoLogo },
  data: () => ({
    loadingPlatforms: false,
    platforms: [],
    currentPlatform: null,
    formData: {
      name: null,
      id: null,
      token: null
    },
    validateForm: false,
    loading: false,
    rules: [],
  }),
  computed: {
    ...mapState({
      pbx: state => state.auth.pbx,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.width > 650
    },
    isXs() {
      return this.$vuetify.breakpoint.width > 360
    },
  },
  mounted() {
    this.loadPlatform()
  },
  methods: {
    ...mapActions({
      logout: authTypes.actions.LOG_OUT,
    }),
    ...mapMutations({
      setPbx: authTypes.mutations.SET_ACCOUNT_PBX
    }),
    async loadPlatform() {
      this.loadingPlatforms = true

      const { success, payload, message } = await getPlatforms()

      if (success) {
        this.platforms = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingPlatforms = false
    },
    selectPlatform(platform) {
      this.currentPlatform = {
        name: platform.name.toLowerCase(),
        id: platform.id
      }
    },
    async connect() {
      await this.validationForm()
      if (this.$refs.form.validate()) {
        this.loading = true

        const formData = this.currentPlatform.name === 'telnyx'
          ? { token: this.formData.token }
          : this.formData

        formData['pbx_id'] = this.currentPlatform.id
        
        const { success, message } = await attachPbx(formData)
        
        if (success) {
          this.setPbx(true)
          this.$notify({ type: 'success-bg', text: message })
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }
        this.loading = false
      }
    },
    validationForm() {
      this.rules = [
        v => !!v || 'This field is required',
        v => !!v && v.length >= 2 || 'Name cannot be shorter than two characters'
      ]
    },
  },
  watch: {
    pbx: {
      immediate: true,
      handler(value) {
        if (value) {
          this.$router.push({ name: 'Reporting' })
        }
      },
    },
    currentPlatform: {
      deep: true,
      handler(value) {
        if (!value) {
          this.formData = {
            name: null,
            id: null,
            token: null
          }
        }
      },
    },
  },
}
</script>

<style lang="scss">
.container.pbxLayoutBg.container--fluid.fill-height {
  position: relative;
  overflow: hidden;
  
  .pbx-bg {
    width: 100%;
    height: 109vh;
    position: absolute;
    left: calc(40vw - 785px);
    top: 53%;
    transform: translateY(-50%) scale(1.1);
    background-image: url(/images/pbxBg.png);
    background-position: left center;
  }

  & .pbxConnect {
    
    &.theme--light {
      box-shadow: 0 8px 40px rgba(23, 10, 60, 0.28)!important;
    }
    
    &.theme--dark {
      box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.68)!important;
    }
  
  
    .form-pbx {
      
      .pbx-field {
        
        & > .v-input {
          color: #FFFFFF!important;
  
          &.theme--light:not(.input-number-default) {
            & > .v-input__control {
              & > .v-input__slot {
                border: 1px solid #C9A9F5!important;
        
                &.inputFocus {
                  background-color: #8363E0!important;
                }
              }
            }
          }
  
          & > .v-input__control {
            & > .v-input__slot {
              input {
                color: #FFFFFF!important;
              }
            }
          }
  
          &.error--text {
            & > .v-input__control {
              & > .v-input__slot {
                border: 1px solid var(--v-pink-base)!important;
              }
            }
          }
        }
  
        & > .cancel-btn {
          border-color: var(--v-pbxCancelBtnBorder-base)!important;
          
          &:hover {
            border-color: var(--v-pink-base)!important;
  
            & > .v-btn__content {
              span {
                color: #FFFFFF!important;
              }
            }
          }
          
          & > .v-btn__content {
            span {
              color: #F4F0F9!important;
            }
          }
        }
  
        & > .action-btn {
          &.theme--light:hover {
            background-color: var(--v-actionButtonForDarkHoverBg-base)!important;
  
            & > .v-btn__content {
              span {
                color: var(--v-pbxActionBtnTextHover-base)!important;
              }
            }
          }
          &.theme--dark:hover {
            background-color: var(--v-actionButtonHoverBg-base)!important;
  
            & > .v-btn__content {
              span {
                color: var(--v-pbxActionBtnTextHover-base)!important;
              }
            }
          }
          
          background-color: var(--v-pbxActionBtnBg-base)!important;
    
          & > .v-btn__content {
            span {
              color: var(--v-pbxActionBtnText-base)!important;
            }
          }
        }
      }
    }
  }

  & .container-pbx-info {

    & .connect-pbx-info{
      border: 1px solid var(--v-pbxInfoBorder-base);
    }
  }
}

@media screen and (max-width: 1280px) {
  .container.pbxLayoutBg.container--fluid.fill-height {
    
    .pbx-bg {
      width: 150%;
      transform: translateY(-50%) scale(1);
    }
  }
}

@media screen and (max-width: 760px) {
  .container.pbxLayoutBg.container--fluid.fill-height {
    
    .pbx-bg {
      left: -150px;
    }
  }
}

// ipad pro
@media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: portrait)  {
  .container.pbxLayoutBg.container--fluid.fill-height {
    
    .pbx-bg {
      width: 100%;
      top: 52%;
      background-position: -265px;
      background-size: 115% 100%;
      height: 100vh;
      left: calc(40vw - 590px);
      transform: translateY(-50%) scale(1.1);
    }
  }
}
</style>
